import { defineStore } from 'pinia'

interface FounderProfilesResponse {
  data: Post[]
}

export const useFounderProfilesStore = defineStore({
  id: 'foundersStore',
  state: () => ({
    data: [] as FounderProfilesResponse['data'],
    loading: false,
    lastFetch: 0
  }),
  actions: {
    async fetch() {
      // Cache check - prevent refetch if data is less than 5 minutes old
      const CACHE_TIME = 5 * 60 * 1000 // 5 minutes
      if (this.data.length && Date.now() - this.lastFetch < CACHE_TIME) {
        return this.data
      }

      try {
        this.loading = true
        const config = useRuntimeConfig()
        const baseUrl = config.public.CMS_PRODUCTION_BASE_URL || process.env.CMS_PRODUCTION_BASE_URL

        const response = await $fetch<FounderProfilesResponse>(`${baseUrl}/founders?populate=*`, {
          timeout: 10000 // Add timeout to prevent hanging requests
        })

        this.data = response.data
        this.lastFetch = Date.now()
        return this.data
      } catch (error) {
        console.error('Failed to fetch founders:', error)
        throw error
      } finally {
        this.loading = false
      }
    }
  }
})